import styled from '@emotion/styled'

export const UserInformationsSection = styled.section``

export const UserInformationsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 50px;
  border-bottom: 2px solid #1a1c1e;
`

export const UserInformationsContentSection = styled.div`
  display: flex;
  align-items: center;
`

export const UserInformationsContentSectionInfo = styled.div`
  margin-left: 20px;
`

export const UserInformationsContentSectionName = styled.h4`
  font-size: 20px;
  font-weight: 600;
`

export const UserInformationsContentSectionWelcome = styled.p`
  font-weight: 300;
  opacity: 0.8;
`
