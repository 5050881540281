import styled from '@emotion/styled'

export const NavbarSection = styled.section`
  padding-block: 50px;
`

export const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
