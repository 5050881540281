import styled from '@emotion/styled'

export const RechargePayments = styled.div`
  display: flex;
  flex-direction: column;
`

export const RechargePayment = styled.div<{ actived?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.actived ? '#1a1b1d' : 'transparent')};
  padding: 10px;
  border-radius: 3px;
  opacity: ${(props) => (props.actived ? '1.0' : 0.5)};
  transition: ease-in-out 0.3s;
  margin-top: 20px;
  img {
    margin-right: 10px;
  }
  &:hover {
    background: #1a1b1d;
  }
`
