import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'

import Button from '../../../styles/shared/Button'
import Form from '../../../styles/shared/Form'

import { api } from '../../../services/api'
import { FaLock, FaRegEnvelope, FaRegFileAlt, FaRegUser } from 'react-icons/fa'

import React, { useState, useContext } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'

import ReactInputMask from 'react-input-mask'
import { toast } from 'react-toastify'

export default function Profile({ isOpen, onClose }: any) {
  const { user } = useContext(AuthContext)

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [password, setPassword] = useState('')
  const [passwordNew, setPasswordNew] = useState('')

  const update = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    setLoading(true)

    if (!name || !email || !password) {
      toast('Preencha os campos.', {
        type: 'error',
      })

      setLoading(false)
    }

    await api
      .put('v1/users', {
        name,
        email,
        password,
        passwordNew,
      })
      .then((response) => {
        toast('Conta atualizada com sucesso.', {
          type: 'success',
        })

        setLoading(false)
      })
      .catch((error) => {
        toast(error.response.data.message, {
          type: 'error',
        })

        setLoading(false)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent background="#101213">
        <ModalHeader>Configurações</ModalHeader>
        <ModalCloseButton />
        <Form method="POST" onSubmit={update}>
          <ModalBody>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <FaRegUser color="#0bc5ea" />
              </InputLeftElement>
              <Input
                variant="flushed"
                type="text"
                placeholder="Nome"
                defaultValue={user?.name}
                onChange={(event) => setName(event.target.value)}
                isReadOnly={loading}
                required
              />
            </InputGroup>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <FaRegFileAlt color="#0bc5ea" />
              </InputLeftElement>
              <Input
                as={ReactInputMask}
                mask={
                  user?.userDocument.type === 'CNPJ'
                    ? '99.999.999/9999-99'
                    : '999.999.999-99'
                }
                variant="flushed"
                type="text"
                placeholder="Documento"
                value={user?.userDocument.document}
                isReadOnly
                required
              />
            </InputGroup>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <FaRegEnvelope color="#0bc5ea" />
              </InputLeftElement>
              <Input
                variant="flushed"
                type="email"
                placeholder="E-mail"
                defaultValue={user?.email}
                onChange={(event) => setEmail(event.target.value)}
                isReadOnly={loading}
                required
              />
            </InputGroup>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <FaLock color="#0bc5ea" />
              </InputLeftElement>
              <Input
                variant="flushed"
                type="password"
                placeholder="Senha atual"
                onChange={(event) => setPassword(event.target.value)}
                isReadOnly={loading}
                required
              />
            </InputGroup>
            <InputGroup mt={4}>
              <InputLeftElement pointerEvents="none">
                <FaLock color="#0bc5ea" />
              </InputLeftElement>
              <Input
                variant="flushed"
                type="password"
                placeholder="Nova senha (opcional)"
                onChange={(event) => setPasswordNew(event.target.value)}
                isReadOnly={loading}
              />
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" fill disabled={loading}>
              Salvar alterações
            </Button>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  )
}
