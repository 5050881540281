import {
  Avatar,
  Container,
  Skeleton,
  SkeletonCircle,
  useDisclosure,
} from '@chakra-ui/react'
import { AuthContext } from '../../../contexts/AuthContext'

import {
  UserInformationsContent,
  UserInformationsContentSection,
  UserInformationsContentSectionInfo,
  UserInformationsContentSectionName,
  UserInformationsContentSectionWelcome,
  UserInformationsSection,
} from '../../../styles/components/app/shared/UserInformations'

import Button from '../../../styles/shared/Button'

import Icon from '../../../styles/shared/Icon'
import { useContext } from 'react'
import Recharge from './Recharge'

export default function UserInformations() {
  const { user } = useContext(AuthContext)

  const {
    isOpen: isOpenRecharge,
    onOpen: onOpenRecharge,
    onClose: onCloseRecharge,
  } = useDisclosure()

  return (
    <UserInformationsSection>
      <Container maxWidth="container.xl">
        <UserInformationsContent>
          <UserInformationsContentSection>
            {user ? (
              <Avatar
                name={`${user?.name}`}
                bg="cyan.500"
                color="white"
                src="https://bit.ly/broken-link"
                size="lg"
              />
            ) : (
              <SkeletonCircle
                startColor="#232427"
                endColor="#1a1c1e"
                size={'65px'}
              />
            )}
            <UserInformationsContentSectionInfo>
              {user ? (
                <UserInformationsContentSectionName>
                  Olá, {user?.name}! 👋
                </UserInformationsContentSectionName>
              ) : (
                <Skeleton
                  startColor="#232427"
                  endColor="#1a1c1e"
                  height="20px"
                />
              )}
              <UserInformationsContentSectionWelcome>
                Ficamos felizes em ver você novamente!
              </UserInformationsContentSectionWelcome>
            </UserInformationsContentSectionInfo>
          </UserInformationsContentSection>
          <Button iconCenter onClick={onOpenRecharge}>
            <Icon
              className="fa-solid fa-wallet"
              size="18px"
              style={{ marginRight: '5px' }}
            />{' '}
            R${' '}
            {user
              ? user?.userWallet?.value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })
              : '0,00'}
          </Button>
        </UserInformationsContent>
      </Container>
      <Recharge isOpen={isOpenRecharge} onClose={onCloseRecharge} />
    </UserInformationsSection>
  )
}
