import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Image,
  useDisclosure,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { api } from '../../../../services/api'
import Button from '../../../../styles/shared/Button'
import Form from '../../../../styles/shared/Form'
import {
  RechargePayment,
  RechargePayments,
} from '../../../../styles/components/app/shared/Recharge'

import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Pix from './Pix'

export default function Recharge({ isOpen, onClose }: any) {
  const [loading, setLoading] = useState(false)

  const [value, setValue] = useState<any>(1)
  const [valueDiscount, setValueDiscount] = useState(0)
  const [coupon, setCoupon] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')

  const {
    isOpen: isOpenPix,
    onOpen: onOpenPix,
    onClose: onClosePix,
  } = useDisclosure()

  const [pixQrCode, setPixQrCode] = useState('')
  const [pixCopyAndPaste, setPixCopyAndPaste] = useState('')

  const recharge = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    setLoading(true)

    if (!value || !paymentMethod) {
      toast('Preencha todos os campos.', {
        type: 'error',
      })

      setLoading(false)
    }

    await api
      .post('v1/users/wallet', {
        value,
        paymentMethod,
        discount: coupon,
      })
      .then((response) => {
        toast('Recarga realizada com sucesso.', {
          type: 'success',
        })

        setPixQrCode(response.data.pix.qr_code_base64)
        setPixCopyAndPaste(response.data.pix.qr_code)

        onOpenPix()
        onClose()

        setLoading(false)
      })
      .catch((_error) => {
        toast('Ocorreu um erro na recarga.', {
          type: 'error',
        })

        setLoading(false)
      })
  }

  const applyCoupon = async () => {
    setLoading(true)

    if (!coupon) {
      toast('Insira o cupom de desconto.', {
        type: 'error',
      })

      return setLoading(false)
    }

    await api
      .post('v1/users/wallet/discount', {
        value,
        code: coupon,
      })
      .then((response) => {
        toast('Cupom aplicado com sucesso.', {
          type: 'success',
        })

        setValueDiscount(response.data)

        setLoading(false)
      })
      .catch((error) => {
        toast(error.response.data.message, {
          type: 'error',
        })

        setLoading(false)
      })
  }

  useEffect(() => {
    setValueDiscount(0)
  }, [value])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent background="#101213">
          <ModalHeader>Recarregar</ModalHeader>
          <ModalCloseButton />
          <Form method="POST" onSubmit={recharge}>
            <ModalBody>
              <Input
                variant="flushed"
                type="number"
                min={1}
                max={999}
                maxLength={3}
                placeholder="Valor"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                isReadOnly={loading}
                required
              />
              <InputGroup mt={4}>
                <Input
                  variant="flushed"
                  type="text"
                  placeholder="Cupom"
                  onChange={(event) => setCoupon(event.target.value)}
                  isReadOnly={loading}
                />
                <InputRightElement width="5rem">
                  <Button
                    padding="3px 10px"
                    onClick={() => applyCoupon()}
                    type="button"
                    disabled={loading}
                  >
                    Aplicar
                  </Button>
                </InputRightElement>
              </InputGroup>
              <RechargePayments>
                <RechargePayment
                  actived={paymentMethod === 'PIX'}
                  onClick={() => setPaymentMethod('PIX')}
                >
                  <Image
                    src="/payments/pix.png"
                    width="auto"
                    height="32px"
                    alt="PIX"
                    onDragStart={(event) => event.preventDefault()}
                  />{' '}
                  PIX
                </RechargePayment>
              </RechargePayments>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" fill disabled={loading}>
                Recarregar (R${' '}
                {valueDiscount
                  ? valueDiscount.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    })
                  : value.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                    })}
                )
              </Button>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
      <Pix
        isOpen={isOpenPix}
        onClose={onClosePix}
        QrCode={pixQrCode}
        CopyAndPaste={pixCopyAndPaste}
      />
    </>
  )
}
