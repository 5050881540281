/* eslint-disable jsx-a11y/alt-text */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Box,
} from '@chakra-ui/react'

import Button from '../../../../styles/shared/Button'
import Router from 'next/router'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { toast } from 'react-toastify'

export default function Pix({ isOpen, onClose, QrCode, CopyAndPaste }: any) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent background="#101213">
        <ModalHeader>Pix</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize={'20px'} fontWeight={'700'}>
            Use o QR Code do Pix para pagar
          </Text>
          <Text>
            Abra o app em que vai fazer a transferência, escaneie a imagem ou
            cole o código do QR Code.
          </Text>
          <Box display={'flex'} justifyContent={'center'} marginTop={10}>
            <Image
              src={`data:image/gif;base64,${QrCode}`}
              userSelect={'none'}
              onDragStart={(event) => event.preventDefault()}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <CopyToClipboard
            text={CopyAndPaste}
            onCopy={() =>
              toast('QR Code copiado com sucesso.', {
                type: 'success',
              })
            }
          >
            <Button margin="0 10px 0 0">Copiar QR Code</Button>
          </CopyToClipboard>
          <Button
            onClick={() => {
              onClose()
              Router.reload()
            }}
          >
            Paguei
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
